module.exports = {
  project_name: "ulsemo", // TODO get rid of
  email_name: "info", // TODO get rid of
  project_domain: "ulsemo.com", // TODO get rid of
  password: "Ulsemo-ReischigIndustries-0", // TODO get rid of
  project_id: "ReischiIndustries-Ulsemo-0",
  email_service: "gmail",
  id_of_project: 1,
  project_server_location: "europe-west1",
  default_shopify_API_version: "2020-01",
  styles: {
    colors: {
      item_name_color: "#fff",
      background_heavy: "#1b1b1b",
    }
  },
  firebase_config: {
    apiKey: "AIzaSyBu2qD1owIFR3NdFO844UtZe8ue2x0FAAg",
    authDomain: "ulsemo.firebaseapp.com",
    databaseURL: "https://ulsemo.firebaseio.com",
    projectId: "ulsemo",
    storageBucket: "ulsemo.appspot.com",
    messagingSenderId: "435102052685",
    appId: "1:435102052685:web:219dd680bb910e78028704",
    measurementId: "G-M8SZWVP7X5"
  },
  google_analytics: {
    email: "firebase-adminsdk-a25s9@template-page.iam.gserviceaccount.com", // TODO change to fetch
    key: "a80f19c305dd4d9daf77c8dc43b7f6bcca6ae35b", // TODO change to fetch
    site_verification: "googlee7469d47b7d070b6.html" // TODO change to fetch
  },
  paypal_urls: {
    sandbox: (version = "v2") => `https://api.sandbox.paypal.com/${version}`,
    production: (version = "v2") => `https://api.paypal.com/${version}`,
    connection_check: cliend_id => `https://www.paypal.com/sdk/js?client-id=${cliend_id}`
  },
  openexchangerates: {
    API_key: "88f6bfd8bbdd434e96d5c22ca912908b"
  },
  other: {
    thumb_image_sizes: [312, 540],
    thumb_content_size: [500]
  },
  stripe_config: {
    API_token: "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",
    price_IDs: {
      base: "price_1K1pDcHLd0x1guwyKze7AmuA",
      metered: "price_1K1pDcHLd0x1guwyoLWF4SHK"
    },
    webhook_secret: {
      local: "whsec_4jBSxVv8jPYmOnK0Nu5PQvN1RZXSGVNH",
      prod: "whsec_ggUaXrhd3fitzZT5ufJnLkTKSwmHbf6w"
    }
  }
}
